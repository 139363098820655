<template>
  <v-app id="inspire">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8" md="4">
          <v-card class="elevation-12">
            <v-toolbar
              style="background: linear-gradient(90deg,#120000 0%,#e90000 100%);!important"
              flat
            >
              <v-toolbar-title class="white--text">Login</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Login"
                  v-model="login"
                  prepend-icon="mdi-account"
                  type="text"
                ></v-text-field>
                <!-- 
                <v-text-field
                  id="password"
                  label="Senha"
                  v-model="password"
                  prepend-icon="mdi-lock"
                  type="password"
                  @keyup.enter="handleSubmit()"
                ></v-text-field> -->

                <v-text-field
                  v-model="password"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-1"
                  prepend-icon="mdi-lock"
                  counter
                  @keyup.enter="handleSubmit()"
                  @click:append="show1 = !show1"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loadBtn"
                style="background: linear-gradient(90deg,#120000 0%,#e90000 100%);!important"
                class="white--text"
                @click="handleSubmit()"
                >Login</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-snackbar
        :timeout="6000"
        v-model="msgCustom.snackbar"
        :color="msgCustom.color"
        :top="true"
      >
        {{ msgCustom.text }}
        <template v-slot:action="{ attrs }">
          <v-btn dark text v-bind="attrs" @click="msgCustom.snackbar = false"
            >Fechar</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      loadBtn: false,
      msgCustom: {
        snackbar: false,
        text: "",
        color: "",
      },
      login: null,
      password: null,
      leilaoCod: null,
      show1: false,
    };
  },
  created() {
    this.pegaLeilaoUrl();
  },
  computed: {
    ...mapState(["loginData"]),
  },
  methods: {
    ...mapActions(["setLogin", "updateLogin"]),
    handleSubmit() {
      this.loadBtn = true;
      const dominioAtual = window.location.host;

      let _dados = {
        Login: this.login,
        Senha: this.password,
        xDominio: "BradescoCarbuy",
        leilaoCod: this.leilaoCod,
        xSession: "111222333",
        xIP: "1.1.1.1",
      };

      if (this.login && this.password) {
        this.setLogin(_dados)
          .then((res) => {
            //   console.log("retorno", res.Retorno.Auxiliar);
            if (res.Retorno.Validacao === "Valido") {
              this.msgSnackBar(true, res.Retorno.Descricao, "info");

              this.$router.push("/" + res.Retorno.Auxiliar, () => {});
            } else {
              this.$router.push(`/login/${this.leilaoCod}`, () => {});
            }
          })
          .catch((err) => {
            console.error(err);
            this.msgSnackBar(true, err, "info");
          })
          .finally(() => ((this.loadBtn = false), (this.password = "")));

        // this.$axios
        //   .post(
        //     `https://wpx.provedor.space/frm2API/Login.aspx?Tipo=BradescoCarbuy&Token=17D8502BB0620AA7565BF02EB417AA3A4355E7EB&LeilaoCod=482`,
        //     _dados
        //   )
        //   .then((response) => {
        //     let resultado = response.data;
        //     if (resultado.Retorno.Validacao === "VALIDO") {
        //       sessionStorage.setItem("dadosLogin", JSON.stringify(resultado));
        //       if (sessionStorage.getItem("jwt") != null) {
        //         this.$emit("loggedIn");
        //         if (this.$route.params.nextUrl != null) {
        //           this.$router.push(this.$route.params.nextUrl);
        //         } else {
        //           this.$router.push("/" + resultado.Retorno.Auxiliar);
        //         }
        //       }
        //     } else {
        //       this.msgSnackBar(true, resultado.Retorno.Descricao, "info");
        //     }
        //   })
        //   .catch((e) => {
        //     console.log("Excecao ao  logar", e.message);
        //     this.msgSnackBar(true, "Aconteceu algo errado", "error");
        //   })
        //   .finally(() => ((this.loadBtn = false), (this.password = "")));
      }
    },
    msgSnackBar(mostrar, msgSnabar, tipoSnack) {
      this.msgCustom.snackbar = mostrar;
      this.msgCustom.text = msgSnabar;
      this.msgCustom.color = tipoSnack;
    },
    pegaLeilaoUrl() {
      this.leilaoCod = this.$route.params.leilaoCod;
      if (!this.leilaoCod) {
        this.msgSnackBar(
          true,
          "Desculpe, mas foi alterado algo na url, favor acesso o site novamente",
          "info"
        );
      }
    },
  },
};
</script>